<template>
  <v-col>
    <v-row>
      <v-col class="mt-3 pt-1" cols="6" sm="3">
        <span>{{ label ? `${label}: ` : "" }}{{ value.length }} Nodes</span>
      </v-col>
      <v-col class="mt-2" cols="6" sm="3">
        <InputCSV
          v-if="!nodesExist"
          :rowHandler="loadPrice"
          :onComplete="saveNodes"
          useHeaders
          @change="$emit('change')"
        >
          <template #activator="{ triggerUpload }">
            <v-btn @click="triggerUpload">Upload Prices</v-btn>
          </template>
        </InputCSV>
        <v-btn v-else color="error" @click="clearNodes" @change="$emit('change')"
          >Clear Nodes</v-btn
        >
      </v-col>

      <template v-if="nodesExist">
        <v-col v-for="reserve in reserves" :key="reserve.id" class="mt-2 shrink">
          <InputCSV
            :rowHandler="loadReserve(reserve.id)"
            :onComplete="saveNodes"
            useHeaders
            @change="$emit('change')"
          >
            <template #activator="{ triggerUpload }">
              <v-btn :disabled="loadedReserves.includes(reserve.id)" @click="triggerUpload"
                >{{ reserve.name }} Reserve Prices</v-btn
              >
            </template>
          </InputCSV>
        </v-col>
      </template>
    </v-row>
  </v-col>
</template>

<script>
import InputCSV from "@/components/InputCSV";

export default {
  name: "InputPriceCustom",
  components: { InputCSV },
  props: {
    label: String,
    reserves: Array,
    value: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  computed: {
    nodesExist() {
      return !!this.value.length;
    },
  },
  data() {
    return {
      nodes: {},
      loadedReserves: [],
    };
  },
  created() {
    if (this.nodesExist) {
      this.value.forEach((node) => {
        this.loadedReserves = node.reserves.map((e) => e.id);
        this.$set(this.nodes, node.name, node);
      });
    }
  },
  methods: {
    clearNodes() {
      this.nodes = {};
      this.saveNodes();
      this.loadedReserves = [];
    },
    // CSVs have a column per node, and a row for every single value
    // Load Price is called on each row. It initializes the object if it doesn't exist, and adds to the appropriate array if it does.
    loadPrice(row) {
      const entries = Object.entries(row);
      entries.forEach((e) => {
        const name = e[0].trim();
        const price = e[1];
        if (!this.nodes[name]) {
          this.$set(this.nodes, name, {
            name,
            prices: [],
            reserves: [],
          });
        }
        this.nodes[name].prices.push(price);
      });
    },
    loadReserve(id) {
      const nodes = Object.entries(this.nodes);
      let rowCount = 0;
      return (row) => {
        rowCount += 1;
        const entries = Object.entries(row);

        if (entries.length === 0) {
          return;
        }
        if (nodes.length > entries.length) {
          throw Error(`File is missing nodes at row ${rowCount}`);
        } else if (nodes.length < entries.length) {
          throw Error(`File has too many nodes at row ${rowCount}`);
        }
        entries.forEach((node) => {
          const name = node[0].trim();
          const price = node[1];
          if (this.nodes[name].reserves[id] == null) {
            this.$set(this.nodes[name].reserves, id, {
              id,
              prices: [],
            });
          }

          this.nodes[name].reserves[id].prices.push(price);
        });

        if (!this.loadedReserves.includes(id)) this.loadedReserves.push(id);
      };
    },
    saveNodes() {
      const nodes = Object.values(this.nodes).map((node) => ({
        ...node,
        reserves: Object.values(node.reserves),
      }));
      console.debug("nodes", this.nodes);
      this.$emit("input", nodes);
    },
  },
  /*
  Original
    [{
      node1: price,
      node2: price,
      node3: price
    }]
  Result
    [{
      name: "node1"
      prices: [],
      reserves: [{id: 1, prices: []}],
    }]
  */
};
</script>

<style></style>
